/// <reference path="_all.d.ts" />
"use strict";
module BASRaT {

    export interface IBASRaTrootScope extends ng.IRootScopeService {
        graduateMember: boolean;
        viewingMemberID: string;
        viewedMemberID: string;
        viewedAspNetMemberId: string;
        isAdmin: boolean;
        searching: boolean;
        simpleMember: Models.SimpleMember;
    }

    angular.module('BASRaT', ['ui','ngPrettyJson']).run(function ($rootScope: IBASRaTrootScope, $window: ng.IWindowService) {

        $rootScope.graduateMember = false;
        $rootScope.searching = false;
        $rootScope.$watch(() => {

            return BASRaT.Services.Constants.viewingMemberID

        }, (n, o) => {

            console.log("changed", n);
            $rootScope.viewedMemberID = BASRaT.Services.Constants.viewedMemberID;
            $rootScope.viewingMemberID = BASRaT.Services.Constants.viewingMemberID;
            $rootScope.isAdmin = BASRaT.Services.Constants.isAdmin;
            $rootScope.viewedAspNetMemberId = BASRaT.Services.Constants.viewedAspNetMemberId;
            $rootScope.simpleMember = BASRaT.Services.Constants.simpleMember;
            });

    })
}
